import React, {useEffect, useRef, useState} from 'react';
import {WarnMessage, Wrapper} from './EventAgenda.styles';
import {useBaseScreenHook} from '../../../logic/core/base-screen.hook';
import {
    activateMeetingPoint,
    activateVoting,
    addMarker,
    changePositionAgendaPoint,
    changePositionVoting,
    deleteAgendaPoint,
    deleteVoting,
    endMeeting,
    excludeParticipantFromVoting,
    includeParticipantToVoting,
    removeMarker,
    startMeeting,
    useEvent
} from '../../../logic/events/events';
import EventHeader from '../EventHeader';
import Loader from '../../../components/Loader';
import iconAdd from '../../../assets/icon-plus.svg'
import ContextMenu from '../../../components/ContextMenu';
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';
import iconMenu from '../../../assets/icon-menu.svg';
import Modal from '../../../components/Modal';
import EventTitleModal from './EventTitleModal';
import {
    AgendaPoint,
    EventPermission, EventTimerData,
    EventType,
    MeetingStatus,
    SpecialMarker, VoteCast,
    VoteParticipant,
    Voting,
    VotingStatus
} from '../../../logic/events/events.model';
import {NavLink, useLocation, useParams, useSearchParams} from 'react-router-dom';
import ConfirmationModal from '../../../components/ConfirmationModal';
import EventVotingCreationModal from './EventVotingCreationModal';
import iconVoting from '../../../assets/icon-voting.svg'
import iconPdf from '../../../assets/icon-pdf-blue.svg'
import iconText from '../../../assets/icon-text.svg'
import iconResults from '../../../assets/icon-results.svg'
import EventVotingModal from './EventVotingModal';
import DocumentPreviewModal from "./DocumentPreviewModal";
import VotingCancelModal from "./VotingCancelModal";
import VotingEditMembersModal from "./VotingExcludeMembersModal";
import AgendaPointsModal from "./AgendaPointsModal";
import OutcomeDecision from "./OutcomeDecision";
import EventTimer from "./EventTimer";
import {downloadByUrl, saveFile} from "../../../logic/files/files.service";
import EventInfoDescription from "../EventInfoDescription";
import {AppRoutes} from "../../../logic/core/routes";
import {useEventTimer} from "../../../logic/events/event-timer";

const EventAgendaScreen = () => {
    const {organizationId, eventId} = useParams();
    const {renderError, t, setError, loading, setLoading, i18n} = useBaseScreenHook();
    const {event, organization} = useEvent();
    const eventTimerData: EventTimerData = useEventTimer({});
    const [openPointModal, setOpenPointModal] = useState(false);
    const [openVotingCreationModal, setOpenVotingCreationModal] = useState<string | null>(null);
    const [openVotingModal, setOpenVotingModal] = useState<{
        agendaPointId: string,
        voteId: string,
        voting: Voting
    } | null>(null);
    const [openVotingResultModal, setOpenVotingResultModal] = useState<{
        agendaPointId: string,
        voteId: string,
        voting: Voting
    } | null>(null);
    const [pointToEdit, setPointToEdit] = useState<AgendaPoint | null>(null);
    const [votingToEdit, setVotingToEdit] = useState<Voting | null>(null);
    const [votingToCancel, setVotingToCancel] = useState<{ voting: Voting, agenda: AgendaPoint } | null>(null);
    const [excludeMembersModal, setExcludeMembersModal] = useState<{
        voting: Voting,
        agenda: AgendaPoint
    } | null>(null);
    const [decidingVoteModal, setDecidingVoteModal] = useState<{ voting: Voting, agenda: AgendaPoint } | null>(null);
    const [requiredVoteModal, setRequiredVoteModal] = useState<{ voting: Voting, agenda: AgendaPoint } | null>(null);
    const [showAgendaPointModal, setShowAgendaPointModal] = useState<{
        voting: Voting,
        agenda: AgendaPoint
    } | null>(null);
    const [votingToDuplicate, setVotingToDuplicate] = useState<Voting | null>(null);
    const [pointToDelete, setPointToDelete] = useState<AgendaPoint | null>(null);
    const [voteToDelete, setVoteToDelete] = useState<{ voting: Voting, agenda: AgendaPoint } | null>(null);
    const [documentPreviewId, setDocumentPreviewId] = useState<string | null>(null);
    const [endMeetingConfirmation, setEndMeetingConfirmation] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<Set<string>>(new Set([]));
    const [params] = useSearchParams();
    const startDate = useRef<Date | null>(null);
    const finishDate = useRef<Date | null>(null);
    const [, setDates] = useState<{ start: string, finish: string }>({start: '', finish: ''});
    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const location = useLocation();

    useEffect(() => {
        if (event?.content?.meetingStartedTimestamp) {
            startDate.current = new Date(event?.content?.meetingStartedTimestamp);
        }
        if (event?.content?.basic.finishDate) {
            finishDate.current = new Date(event?.content?.basic.finishDate);
        }
        setDates({
            start: startDate.current ? dateFormatter(startDate.current) : '',
            finish: finishDate.current ? dateFormatter(finishDate.current) : ''
        });
        _handleAutoOpenCloseVotingWindow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event])

    useEffect(() => {
        _handleCircularEvent()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event?.content?.type])

    const _handleCircularEvent = () => {
        if (event?.content?.type === EventType.CIRCULAR && params.get('first') === 'true') {
            if (event?.content?.agenda.items[0].votes.length === 0) {
                setOpenVotingCreationModal(event?.content?.agenda.items[0].id);
            }
        }
    }
    const _handleAutoOpenCloseVotingWindow = () => {
        if (event) {
            const item = event.content?.agenda.items.find((i) => i.votes.find((v) => v.open));
            const voting = event.content?.type === EventType.CIRCULAR ? null : item?.votes.find((v) => v.open);

            if (voting) {
                setOpenVotingResultModal(null);
                if (!openVotingModal) {
                    setOpenVotingModal({
                        agendaPointId: item!.id,
                        voteId: voting.id,
                        voting,
                    })
                } else if (openVotingModal && openVotingModal.voteId !== voting.id) {
                    setOpenVotingModal(null);
                    setTimeout(() => {
                        setOpenVotingModal({
                            agendaPointId: item!.id,
                            voteId: voting.id,
                            voting,
                        })
                    })
                }
            } else {
                if (openVotingModal) {
                    if ((openVotingModal.voting.status === VotingStatus.Pending && event.permissions[EventPermission.canEnterVoteInPendingStatus]) || event.content?.type === EventType.CIRCULAR) {

                    } else {
                        setOpenVotingModal(null)
                    }
                }
            }
        }
    }

    if (!event || !organization) {
        return <Loader/>
    }
    const _renderNo = (index: number): string => {
        index = index + 1;

        if (index < 10) {
            return `0${index}`
        } else {
            return `${index}`
        }

    }

    const _votingCancelModalClosed = (openNew: boolean) => {
        if (openNew) {
            _duplicateVoting(votingToCancel!.agenda, votingToCancel!.voting);
        }
        setVotingToCancel(null);
    }
    const _votingModalClosedWithDuplicate = (agenda: AgendaPoint, voting: Voting) => {
        _duplicateVoting(agenda, voting);
        setOpenVotingModal(null);
        setOpenVotingResultModal(null);
    }
    const _delete = async () => {
        const res = await deleteAgendaPoint(organizationId!, eventId!, pointToDelete!.id);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setPointToDelete(null);
    }

    const _changePointPosition = async (point: AgendaPoint, index: number) => {
        const res = await changePositionAgendaPoint(organizationId!, eventId!, point.id, index);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _changeVotePosition = async (point: AgendaPoint, vote: Voting, index: number) => {
        const res = await changePositionVoting(organizationId!, eventId!, point.id, vote.id, index);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _moveVoteToOtherPoint = async (point: AgendaPoint, vote: Voting) => {
        setShowAgendaPointModal({
            agenda: point,
            voting: vote,
        });
    }

    const _startMeeting = async () => {
        setLoading(true);
        const res = await startMeeting(organizationId!, eventId!);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _endMeeting = async () => {
        setLoading(true);
        const res = await endMeeting(organizationId!, eventId!);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setEndMeetingConfirmation(false);
    }

    const _activatePoint = async (a: AgendaPoint) => {
        setLoading(true);
        const res = await activateMeetingPoint(organizationId!, eventId!, a.id);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _activeVoting = async (a: AgendaPoint, v: Voting) => {
        setLoading(true);
        const res = await activateVoting(organizationId!, eventId!, a.id, v.id);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _deleteVoting = async () => {
        setLoading(true);
        const res = await deleteVoting(organizationId!, eventId!, voteToDelete!.agenda.id, voteToDelete!.voting.id);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        setVoteToDelete(null);

    }
    const _duplicateVoting = async (a: AgendaPoint, v: Voting) => {
        setVotingToDuplicate(v);
        setOpenVotingCreationModal(a.id);
    }
    const _excludeMembers = async (a: AgendaPoint, v: Voting) => {
        setExcludeMembersModal({
            agenda: a,
            voting: v,
        })
    }
    const _setDecidingVote = async (a: AgendaPoint, v: Voting) => {
        setDecidingVoteModal({
            agenda: a,
            voting: v,
        })
    }
    const _setRequiredVote = async (a: AgendaPoint, v: Voting) => {
        setRequiredVoteModal({
            agenda: a,
            voting: v,
        })
    }

    const _editVoting = async (a: AgendaPoint, v: Voting) => {
        setVotingToEdit(v);
        setOpenVotingCreationModal(a.id);
    }

    const _previewDocument = (v: Voting) => {
        setDocumentPreviewId(v.documentId);
    }
    const _downloadReport = async (agendaPointId: string, voteId: string) => {
        downloading.add(voteId)
        setDownloading(new Set(Array.from(downloading)));
        const res = await downloadByUrl(`/organizations/${organizationId!}/events/live/${eventId!}/agenda/${agendaPointId}/votes/${voteId}/report`);
        downloading.delete(voteId)
        setDownloading(new Set(Array.from(downloading)));
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        const saveRes = await saveFile(res.data!.data!, res.data?.name!, res.data?.type!);

        if (saveRes.isFailure()) {
            setError(saveRes.failure!);
            return;
        }

    }

    const _renderDecidingVote = (v: Voting) => {
        const cast = v.voteCasts.find((c) => c.specialMarkers.includes(SpecialMarker.DecidingVote))

        if (!cast) {
            return null;
        }
        return <div className="exclude-members">
            <strong>{t('eventAgenda.decidingVoteMember')}</strong> {cast.participantName}
        </div>
    }
    const _renderRequiredVote = (v: Voting) => {
        const cast = v.voteCasts.filter((c) => c.specialMarkers.includes(SpecialMarker.RequiredVote))

        if (!cast || !cast.length) {
            return null;
        }
        return <div className="exclude-members">
            <strong>{t('eventAgenda.requiredVoteMember')}</strong> {cast.map(c => c.participantName).join(', ')}
        </div>
    }

    const _renderCastedVote = (v: Voting) => {
        const voteCast: VoteCast | null = v?.voteCasts.find((c) => c.participantId === event?.participant?.id) ?? null;
        if (!voteCast) {
            return null;
        }
        if (voteCast?.castedVote) {
            return <div className={'casted-vote'}>{ t('eventAgenda.castedVote') }</div>;
        }
        return <div className={'casted-vote pending'}>{t('eventAgenda.pendingVote')}</div>;
    }

    if (!event) {
        return <Wrapper>
            <Loader/>
            {renderError()}
        </Wrapper>
    }

    return <Wrapper>
        <div className="header">
            <EventHeader event={event} organization={organization}/>

            <div className="meeting-status">
                {
                    event?.permissions[EventPermission.canStartEvent] && event?.content?.meetingStatus === MeetingStatus.Draft
                        ? <button className="button" onClick={_startMeeting} disabled={loading || !eventTimerData.canStart}>
                            {t('eventAgenda.startMeeting')}
                        </button>
                        : null
                }
                {
                    event?.content?.meetingStatus === MeetingStatus.InProgress || event?.content?.meetingStatus === MeetingStatus.Finished || event?.content?.meetingStatus === MeetingStatus.FinishRequested
                        ? <div className={'status'}>

                            <div className="status-text">
                                <div className={`dot ${event?.content?.meetingStatus}`}>

                                </div>

                                <div className={'label'}>
                                    {
                                        event?.content?.meetingStatus === MeetingStatus.FinishRequested
                                            ? t(`eventVotingModal.status.${event?.content?.meetingStatus}`)
                                            : <>
                                                {t('eventVotingModal.meeting')}&nbsp;
                                                <span>{t(`eventVotingModal.status.${event?.content?.meetingStatus}`)}</span>
                                            </>
                                    }

                                </div>
                            </div>

                            {
                                event?.content?.meetingStatus === MeetingStatus.FinishRequested && event.reportGenerationProgress
                                    ? <div className={'documents-counter'}>
                                        {event.reportGenerationProgress?.generated}/{event.reportGenerationProgress?.total}
                                    </div>
                                    : null
                            }
                            {
                                event?.content?.meetingStatus === MeetingStatus.InProgress
                                    ? <>
                                        {
                                            startDate.current ?
                                                <EventTimer startDate={startDate.current} finishDate={finishDate.current}/> :
                                                <Loader/>
                                        }

                                        {event?.permissions[EventPermission.canFinishEvent] ?
                                            <button className="button warn" onClick={() => setEndMeetingConfirmation(true)}
                                                    disabled={loading}>
                                                {t('eventAgenda.endMeeting')}
                                            </button> : null}
                                    </>
                                    : null
                            }

                        </div>
                        : null
                }

            </div>
        </div>

        {
            event.content?.type === EventType.CIRCULAR
                ? <div className={'event-description'}><EventInfoDescription event={event} organization={organization}/>
                </div>
                : null
        }
        {
            event.content?.type === EventType.LIVE
                ? <div className="sub-title">
                    {t('eventAgenda.title')}
                </div>
                : null
        }


        <div className="list">
            {
                event?.content?.agenda.items.map((a, i) => {
                    return <div className="agenda" key={i}>
                        <div className={`point ${a.activated ? 'active' : ''}`}>
                            <div className="no">{_renderNo(i)}</div>
                            <div className="name">
                                <div className="title">
                                    {a.title}
                                </div>

                                {
                                    event?.permissions.canManageVote && event.content?.canCreateVote
                                        ? <button className={'button text icon add-voting'}
                                                  onClick={() => setOpenVotingCreationModal(a.id)}>
                                            <img src={iconAdd} alt="icon"/>
                                            {t('eventAgenda.addVoting')}
                                        </button>
                                        : null
                                }
                                {

                                    event?.content?.canManageAgenda || event?.content?.canChangeAgendaItem
                                        ? <ContextMenu icon={iconMenu}>
                                            {
                                                event?.content?.canManageAgenda && event?.permissions.canActivateAgendaItem && event?.content?.agenda.canActivate ?
                                                    <ContextMenuItem onClick={() => {
                                                        _activatePoint(a)
                                                    }}>
                                                        {t('eventAgenda.activatePoint')}
                                                    </ContextMenuItem>
                                                    : null
                                            }

                                            {
                                                event?.permissions.canManageAgenda && i > 0
                                                    ? <ContextMenuItem onClick={() => {
                                                        _changePointPosition(a, i - 1);
                                                    }}>
                                                        {t('eventAgenda.moveUp')}
                                                    </ContextMenuItem>
                                                    : null
                                            }
                                            {
                                                event?.permissions.canManageAgenda && i < event?.content!.agenda.items.length - 1
                                                    ? <ContextMenuItem onClick={() => {
                                                        _changePointPosition(a, i + 1);

                                                    }}>
                                                        {t('eventAgenda.moveDown')}
                                                    </ContextMenuItem>
                                                    : null
                                            }
                                            {
                                                event?.permissions.canManageAgenda && event?.content?.canChangeAgendaItem
                                                    ? <ContextMenuItem onClick={() => {
                                                        setPointToEdit(a);
                                                        setOpenPointModal(true);
                                                    }}>
                                                        {t('eventAgenda.edit')}
                                                    </ContextMenuItem>
                                                    : null
                                            }
                                            {
                                                event?.permissions.canManageAgenda
                                                    ? <ContextMenuItem onClick={() => setPointToDelete(a)}>
                                                <span className={'delete'}>
                                                {t('eventAgenda.delete')}
                                                </span>
                                                    </ContextMenuItem>
                                                    : null
                                            }


                                        </ContextMenu>
                                        : null
                                }

                            </div>
                        </div>
                        {
                            a.votes.length
                                ? <div className={`votes ${a.activated ? 'active' : ''}`}>
                                    {
                                        a.votes.map((v, n) => {
                                            return <div className={'vote'} key={v.id}>
                                                <div className="vote-name">
                                                    <div className="name">
                                                        <div
                                                            className={`dot ${a.activated ? 'active' : ''} ${v.status}`}></div>
                                                        {v.name}
                                                    </div>

                                                    {event?.permissions.canManageVote
                                                        ? <ContextMenu icon={iconMenu}>
                                                            {
                                                                v.availableActions.canBeDuplicated
                                                                    ?
                                                                    <ContextMenuItem onClick={() => _duplicateVoting(a, v)}>
                                                                        {t('eventAgenda.duplicateVoting')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }

                                                            {
                                                                v.availableActions.canChangePosition && n > 0
                                                                    ? <ContextMenuItem onClick={() => {
                                                                        _changeVotePosition(a, v, n - 1);
                                                                    }}>
                                                                        {t('eventAgenda.moveVoteUp')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canChangePosition && n < a.votes.length - 1
                                                                    ? <ContextMenuItem onClick={() => {
                                                                        _changeVotePosition(a, v, n + 1);

                                                                    }}>
                                                                        {t('eventAgenda.moveVoteDown')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canChangePosition
                                                                    ? <>
                                                                        <ContextMenuItem onClick={() => {
                                                                            _moveVoteToOtherPoint(a, v);
                                                                        }}>
                                                                            {t('eventAgenda.moveToOtherPoint')}
                                                                        </ContextMenuItem>
                                                                        <ContextMenuItem onClick={() => _excludeMembers(a, v)}>
                                                                            {t('eventAgenda.excludeMembers')}
                                                                        </ContextMenuItem>
                                                                    </>

                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canAddVoterSpecialMarker
                                                                    ?
                                                                    <ContextMenuItem onClick={() => _setDecidingVote(a, v)}>
                                                                        {t('eventAgenda.decidingVote')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canAddVoterSpecialMarker
                                                                    ?
                                                                    <ContextMenuItem onClick={() => _setRequiredVote(a, v)}>
                                                                        {t('eventAgenda.requiredVote')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canBeUpdated ?
                                                                    <ContextMenuItem onClick={() => _editVoting(a, v)}>
                                                                        {t('eventAgenda.editVoting')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }

                                                            {
                                                                v.availableActions.canBeActivated
                                                                    ? <ContextMenuItem onClick={() => _activeVoting(a, v)}>
                                                                        {t('eventAgenda.activateVoting')}
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canBeCanceled
                                                                    ? <ContextMenuItem onClick={() => setVotingToCancel({
                                                                        agenda: a,
                                                                        voting: v
                                                                    })}>
                                                                    <span className={'delete'}>
                                                                        {t('eventAgenda.cancelVoting')}
                                                                    </span>
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canBeDeleted
                                                                    ? <ContextMenuItem
                                                                        onClick={() => setVoteToDelete({agenda: a, voting: v})}>
                                                                    <span className={'delete'}>
                                                                        {t('eventAgenda.deleteVoting')}
                                                                    </span>
                                                                    </ContextMenuItem>
                                                                    : null
                                                            }

                                                        </ContextMenu>
                                                        : null}


                                                </div>
                                                <div className="content-wrapper">
                                                    <div className="left-content">
                                                        <div className="actions">
                                                            {
                                                                v.documentId
                                                                    ?
                                                                    <button className="button text icon muted"
                                                                            onClick={() => _previewDocument(v)}>
                                                                        <img src={iconText} alt="icon"/>
                                                                        {t('eventAgenda.showContent')}
                                                                    </button>

                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canShowResults
                                                                    ?
                                                                    <button className="button text icon muted"
                                                                            onClick={() => setOpenVotingResultModal({
                                                                                agendaPointId: a.id,
                                                                                voteId: v.id,
                                                                                voting: v,
                                                                            })}>
                                                                        <img src={iconResults} alt="icon"/>
                                                                        {t('eventAgenda.showResults')}
                                                                    </button>

                                                                    : null
                                                            }
                                                            {
                                                                v.availableActions.canEnterVoting || (event?.permissions.canEnterVoteInPendingStatus && v.status === VotingStatus.Pending)
                                                                    ?
                                                                    <button className="button text icon"
                                                                            onClick={() => setOpenVotingModal({
                                                                                agendaPointId: a.id,
                                                                                voteId: v.id,
                                                                                voting: v,
                                                                            })}>
                                                                        <img src={iconVoting} alt="icon"/>
                                                                        {t('eventAgenda.goToVoting')}
                                                                    </button>

                                                                    : null
                                                            }
                                                            {
                                                                v.hasReportAvailable && event?.permissions.canReadDocument
                                                                    ?
                                                                    <button className="button text icon"
                                                                            disabled={downloading.has(v.id)}
                                                                            onClick={() => _downloadReport(
                                                                                a.id,
                                                                                v.id,)
                                                                            }>
                                                                        <img src={iconPdf} alt="icon"/>
                                                                        {downloading.has(v.id) ?
                                                                            <Loader/> : t('eventAgenda.downloadReport')}
                                                                    </button>

                                                                    : null
                                                            }
                                                        </div>
                                                        {
                                                            _renderRequiredVote(v)
                                                        }
                                                        {
                                                            _renderDecidingVote(v)
                                                        }
                                                        {
                                                            v.excludedParticipants.length
                                                                ? <div className="exclude-members">
                                                                    <strong>{t('eventAgenda.excludedMembers')}</strong> {v.excludedParticipants.join(', ') || '-'}
                                                                </div>
                                                                : null
                                                        }
                                                        {
                                                            v.availableActions.canDecideOnOutcome && event?.permissions.canDecideOnVoteOutcome
                                                                ? <div className={'outcome-decision'}><OutcomeDecision
                                                                    itemId={a.id}
                                                                    voteId={v.id}/>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                    <div className="right-content">
                                                        {
                                                            event?.content?.type === EventType.CIRCULAR
                                                                ? <div className="votes-summary">
                                                                    {_renderCastedVote(v)}
                                                                    <div className="casted-votes-count">
                                                                        { t('eventAgenda.castedVotesCount') } {v.castedVotesCount} / {v.allVotesCount}
                                                                    </div>
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>;
                                        })
                                    }
                                </div>
                                :
                                null
                        }

                        {
                            a.activated && event?.content?.canManageAgenda && event?.permissions.canActivateAgendaItem && event?.content?.agenda.canActivate && i < event.content.agenda.items.length - 1
                                ? <div className="next-point-button"
                                       onClick={() => _activatePoint(event?.content?.agenda.items[i + 1]!)}>
                                    <button className="button outline">
                                        { t('eventAgenda.nextStep') }
                                    </button>
                                </div>
                                : null
                        }

                    </div>
                })
            }
        </div>

        {
            event?.permissions.canManageAgenda && event.content?.canManageAgenda
                ?
                <button className="button text icon" onClick={() => setOpenPointModal(true)}>
                    <img src={iconAdd} alt="icon"/>
                    {t('eventAgenda.addBtn')}

                </button>
                : null
        }
        {
            event.permissions[EventPermission.canSeeNavigationButtons]
                ?
                <div className={'nav-buttons'}>
                    <NavLink to={`../${AppRoutes.eventInfo}${location.search}`}>
                        <button className="button outline muted">
                            {t('event.navigation.backToEventInfo')}
                        </button>
                    </NavLink>
                    <NavLink to={`../${AppRoutes.documents}${location.search}`}>
                        <button className="button">
                            {t('event.navigation.goToDocuments')}
                        </button>
                    </NavLink>
                </div>
                : null
        }

        <Modal width={700} show={openPointModal} onClose={() => {
            setOpenPointModal(false);
            setPointToEdit(null);
        }} backDropClose={false}>
            <EventTitleModal closingItemIndex={event!.content?.agenda.items.findIndex(i => i.closingItem)}
                             point={pointToEdit} onClose={() => {
                setOpenPointModal(false);
                setPointToEdit(null);
            }}/>
        </Modal>
        <Modal width={800} show={!!documentPreviewId} reset={true} onClose={() => {
            setDocumentPreviewId(null);
        }} backDropClose={false}>
            <DocumentPreviewModal documentId={documentPreviewId} onClose={() => {
                setDocumentPreviewId(null);
            }}/>
        </Modal>

        <Modal width={1400} show={!!openVotingCreationModal} onClose={() => {
            setOpenVotingCreationModal(null);
            setVotingToEdit(null);
            setVotingToDuplicate(null);
        }} backDropClose={false} reset={true}>
            <EventVotingCreationModal voting={votingToEdit}
                                      itemId={openVotingCreationModal!}
                                      eventName={event?.content?.basic.name ?? ''}
                                      initialValues={votingToDuplicate}

                                      onClose={() => {
                                          setOpenVotingCreationModal(null);
                                          setVotingToEdit(null);
                                          setVotingToDuplicate(null);
                                      }}/>
        </Modal>

        <Modal width={1400} show={!!openVotingModal || !!openVotingResultModal} backDropClose={false} reset={true}>
            <EventVotingModal
                onClose={() => {
                    setOpenVotingModal(null);
                    setOpenVotingResultModal(null);
                }}
                onCloseWithDuplicate={_votingModalClosedWithDuplicate}
                agendaPointId={openVotingModal?.agendaPointId || openVotingResultModal?.agendaPointId}
                voteId={openVotingModal?.voteId || openVotingResultModal?.voteId}
                previewResults={!!openVotingResultModal}
            />
        </Modal>


        <Modal width={800} show={!!votingToCancel} onClose={() => setVotingToCancel(null)}>
            <VotingCancelModal agenda={votingToCancel?.agenda} voting={votingToCancel?.voting}
                               onClose={_votingCancelModalClosed}
            />
        </Modal>

        <Modal width={700} show={!!excludeMembersModal} onClose={() => setExcludeMembersModal(null)}>
            <VotingEditMembersModal itemId={excludeMembersModal?.agenda.id}
                                    vote={excludeMembersModal?.voting}
                                    onClose={() => setExcludeMembersModal(null)}
                                    title={t('votingExcludeMembersModal.title')}
                                    description={t('votingExcludeMembersModal.description', {name: organization?.name})}
                                    listDescription={t('votingExcludeMembersModal.listDescription', {name: excludeMembersModal?.voting?.name})}
                                    isSelectedFn={(p: VoteParticipant) => !!p?.excluded}
                                    selectedMemberDescription={(p: VoteParticipant) => {
                                        return p.excluded ?
                                            t('votingExcludeMembersModal.excludedLabel', {
                                                name: p.excluded.excludedByName,
                                                date: dateFormatter(new Date(p.excluded.timestamp)),
                                            })
                                            : null
                                    }
                                    }
                                    setButtons={(p: VoteParticipant, set: (p: VoteParticipant) => void, unset) => {
                                        return p.excluded
                                            ?
                                            <button className={'button outline'} onClick={() => set(p)}>
                                                {t('votingExcludeMembersModal.restore')}
                                            </button>
                                            :
                                            <button className={'button warn outline'} onClick={() => unset(p)}>
                                                {t('votingExcludeMembersModal.exclude')}
                                            </button>
                                    }
                                    }
                                    request={(p: VoteParticipant) => {
                                        const itemId: string = excludeMembersModal!.agenda.id;
                                        const voteId: string = excludeMembersModal!.voting.id;
                                        if (p.excluded) {
                                            return includeParticipantToVoting(organizationId!, eventId!, itemId, voteId!, p.id);
                                        } else {
                                            return excludeParticipantFromVoting(organizationId!, eventId!, itemId, voteId!, p.id);
                                        }
                                    }
                                    }
                                    confirmationTitle={(p: VoteParticipant) => {
                                        return p?.excluded
                                            ? t('votingExcludeMemberConfirm.titleInclude')
                                            : t('votingExcludeMemberConfirm.titleExclude')
                                    }}
                                    confirmationSetUnsetLabel={(p: VoteParticipant) => {

                                        return p?.excluded
                                            ? <div className="set">
                                                {t('votingExcludeMemberConfirm.includeLabel')}
                                            </div>
                                            : <div className="unset">
                                                {t('votingExcludeMemberConfirm.excludeLabel')}
                                            </div>
                                    }}
                                    confirmationButtonLabel={(p: VoteParticipant) => {
                                        return p?.excluded
                                            ? t('votingExcludeMemberConfirm.include')
                                            : t('votingExcludeMemberConfirm.exclude')
                                    }}
                                    customMessage={(p: VoteParticipant) => {
                                        if (p?.excluded) {
                                            return null;
                                        }
                                        if (p?.specialMarkers.includes(SpecialMarker.DecidingVote) && p?.specialMarkers.includes(SpecialMarker.RequiredVote)) {
                                            return <WarnMessage>{ t('eventAgenda.warning.decisiveRequiredVote') }</WarnMessage>;
                                        } else if (p?.specialMarkers.includes(SpecialMarker.DecidingVote)) {
                                            return <WarnMessage>{ t('eventAgenda.warning.decisiveVote') }</WarnMessage>;
                                        } else if (p?.specialMarkers.includes(SpecialMarker.RequiredVote)) {
                                            return <WarnMessage>{ t('eventAgenda.warning.requiredVote') }</WarnMessage>;
                                        }
                                        return null;
                                    }}
            />
        </Modal>
        <Modal width={700} show={!!decidingVoteModal} onClose={() => setDecidingVoteModal(null)}>
            <VotingEditMembersModal itemId={decidingVoteModal?.agenda.id}
                                    vote={decidingVoteModal?.voting}
                                    onClose={() => setDecidingVoteModal(null)}
                                    title={t('votingExcludeMembersModal.titleDecidingVote')}
                                    description={t('votingExcludeMembersModal.descriptionDecidingVote', {name: organization?.name})}
                                    listDescription={t('votingExcludeMembersModal.listDescriptionDecidingVote', {name: decidingVoteModal?.voting?.name})}
                                    isSelectedFn={(p: VoteParticipant) => p?.specialMarkers.includes(SpecialMarker.DecidingVote)}
                                    selectedMemberDescription={() => {
                                        return null
                                    }
                                    }
                                    setButtons={(p: VoteParticipant, set: (p: VoteParticipant) => void, unset) => {
                                        return p.specialMarkers.includes(SpecialMarker.DecidingVote)
                                            ?
                                            <button className={'button warn outline'} onClick={() => unset(p)}>
                                                {t('votingExcludeMembersModal.removeDecidingVote')}
                                            </button>
                                            :
                                            <button className={'button muted outline'} onClick={() => set(p)}>
                                                {t('votingExcludeMembersModal.addDecidingVote')}
                                            </button>
                                    }
                                    }
                                    request={(p: VoteParticipant) => {
                                        const itemId: string = decidingVoteModal!.agenda.id;
                                        const voteId: string = decidingVoteModal!.voting.id;
                                        if (p?.specialMarkers.includes(SpecialMarker.DecidingVote)) {
                                            return removeMarker(organizationId!, eventId!, itemId, voteId!, p.id, SpecialMarker.DecidingVote);
                                        } else {
                                            return addMarker(organizationId!, eventId!, itemId, voteId!, p.id, SpecialMarker.DecidingVote);
                                        }
                                    }
                                    }
                                    confirmationTitle={(p: VoteParticipant) => {
                                        return p?.specialMarkers.includes(SpecialMarker.DecidingVote)
                                            ? t('votingExcludeMemberConfirm.titleDecideVoteRemove')
                                            : t('votingExcludeMemberConfirm.titleDecideVoteAdd')
                                    }}
                                    confirmationSetUnsetLabel={(p: VoteParticipant) => {

                                        return p?.specialMarkers.includes(SpecialMarker.DecidingVote)
                                            ? <div className="unset">
                                                {t('votingExcludeMemberConfirm.labelDecideVoteRemove')}
                                            </div>
                                            : <div className="set">
                                                {t('votingExcludeMemberConfirm.labelDecideVoteAdd')}
                                            </div>
                                    }}
                                    confirmationButtonLabel={(p: VoteParticipant) => {
                                        return t('votingExcludeMemberConfirm.confirmDecideVote');
                                    }}
                                    customMessage={(p: VoteParticipant) => {
                                        if (p?.specialMarkers.includes(SpecialMarker.DecidingVote)) {
                                            return null;
                                        }
                                        if (p?.excluded) {
                                            return <WarnMessage>{ t('eventAgenda.warning.excluded') }</WarnMessage>;
                                        }
                                        return null;
                                    }}

            />
        </Modal>

        <Modal width={700} show={!!requiredVoteModal} onClose={() => setRequiredVoteModal(null)}>
            <VotingEditMembersModal itemId={requiredVoteModal?.agenda.id}
                                    vote={requiredVoteModal?.voting}
                                    onClose={() => setRequiredVoteModal(null)}
                                    title={t('votingExcludeMembersModal.titleRequiredVote')}
                                    description={t('votingExcludeMembersModal.descriptionRequiredVote', {name: organization?.name})}
                                    listDescription={t('votingExcludeMembersModal.listDescriptionRequiredVote', {name: requiredVoteModal?.voting?.name})}
                                    isSelectedFn={(p: VoteParticipant) => p?.specialMarkers.includes(SpecialMarker.RequiredVote)}
                                    selectedMemberDescription={() => {
                                        return null
                                    }
                                    }
                                    setButtons={(p: VoteParticipant, set: (p: VoteParticipant) => void, unset) => {
                                        return p.specialMarkers.includes(SpecialMarker.RequiredVote)
                                            ?
                                            <button className={'button warn outline'} onClick={() => unset(p)}>
                                                {t('votingExcludeMembersModal.removeRequiredVote')}
                                            </button>
                                            :
                                            <button className={'button muted outline'} onClick={() => set(p)}>
                                                {t('votingExcludeMembersModal.addRequiredVote')}
                                            </button>
                                    }
                                    }
                                    request={(p: VoteParticipant) => {
                                        const itemId: string = requiredVoteModal!.agenda.id;
                                        const voteId: string = requiredVoteModal!.voting.id;
                                        if (p?.specialMarkers.includes(SpecialMarker.RequiredVote)) {
                                            return removeMarker(organizationId!, eventId!, itemId, voteId!, p.id, SpecialMarker.RequiredVote);
                                        } else {
                                            return addMarker(organizationId!, eventId!, itemId, voteId!, p.id, SpecialMarker.RequiredVote);
                                        }
                                    }
                                    }
                                    confirmationTitle={(p: VoteParticipant) => {
                                        return p?.specialMarkers.includes(SpecialMarker.RequiredVote)
                                            ? t('votingExcludeMemberConfirm.titleRequiredVoteRemove')
                                            : t('votingExcludeMemberConfirm.titleRequiredVoteAdd')
                                    }}
                                    confirmationSetUnsetLabel={(p: VoteParticipant) => {

                                        return p?.specialMarkers.includes(SpecialMarker.RequiredVote)
                                            ? <div className="unset">
                                                {t('votingExcludeMemberConfirm.labelRequiredVoteRemove')}
                                            </div>
                                            : <div className="set">
                                                {t('votingExcludeMemberConfirm.labelRequiredVoteAdd')}
                                            </div>
                                    }}
                                    confirmationButtonLabel={(p: VoteParticipant) => {
                                        return t('votingExcludeMemberConfirm.confirmRequiredVote');
                                    }}
                                    customMessage={(p: VoteParticipant) => {
                                        if (p?.specialMarkers.includes(SpecialMarker.RequiredVote)) {
                                            return null;
                                        }
                                        if (p?.excluded) {
                                            return <WarnMessage>{ t('eventAgenda.warning.excluded') }</WarnMessage>;
                                        }
                                        return null;
                                    }}
            />
        </Modal>

        <Modal width={600} show={!!showAgendaPointModal} onClose={() => setShowAgendaPointModal(null)}>
            <AgendaPointsModal points={event.content?.agenda.items}
                               item={showAgendaPointModal?.agenda}
                               vote={showAgendaPointModal?.voting}
                               onClose={() => setShowAgendaPointModal(null)}
            />
        </Modal>

        <Modal width={600} show={!!pointToDelete} onClose={() => setPointToDelete(null)}>
            <ConfirmationModal
                reject={() => setPointToDelete(null)}
                accept={() => _delete()}
                title={t('eventAgenda.deleteConfirmation')}
            />
        </Modal>

        <Modal width={600} show={!!voteToDelete} onClose={() => setVoteToDelete(null)}>
            <ConfirmationModal
                reject={() => setVoteToDelete(null)}
                accept={() => _deleteVoting()}
                title={t('eventAgenda.deleteVotingConfirmation')}
            />
        </Modal>

        <Modal width={600} show={endMeetingConfirmation} onClose={() => setEndMeetingConfirmation(false)}>
            <ConfirmationModal
                reject={() => setEndMeetingConfirmation(false)}
                accept={() => _endMeeting()}
                acceptLabel={t('eventAgenda.endMeetingConfirmationConfirm')}
                title={t('eventAgenda.endMeetingConfirmation')}
            />
        </Modal>
        {renderError()}
    </Wrapper>
}

export default EventAgendaScreen;
