import React, {useEffect, useState} from 'react';
import {Wrapper} from './OrganizationBillingsPricingScreen.styles';
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import {Failure} from "../../../../logic/core/failure";
import {
    CreditDefinition,
    CreditInvoiceData,
    CreditToBuyWithDefinition
} from "../../../../logic/credits/credits.model";
import {getCreditsOffer, getInvoiceData} from "../../../../logic/credits/credits";
import ErrorModal from "../../../../components/ErrorModal";
import Loader from "../../../../components/Loader";
import Modal from "../../../../components/Modal";
import OrganizationBillingsInvoiceData from "./OrganizationBillingsInvoiceData";
import OrganizationBillingsOrderSummary from "./OrganizationBillingsOrderSummary";

const OrganizationBillingsPricingScreen = () => {
    const {t, i18n} = useBaseScreenHook();
    const {organizationId} = useParams();
    const [error, setError] = useState<Failure | null>(null);
    const [offer, setOffer] = useState<CreditDefinition[] | null>(null);
    const [invoiceData, setInvoiceData] = useState<CreditInvoiceData | null>(null);
    const [orderInvoiceData, setOrderInvoiceData] = useState<CreditInvoiceData | null>(null);

    const numberFormatter = new Intl.NumberFormat(i18n.language, {minimumFractionDigits: 2,  maximumFractionDigits: 2}).format;
    const [openInvoiceData, setOpenInvoiceData] = useState<boolean>(false);
    const [openOrderSummary, setOpenOrderSummary] = useState<boolean>(false);
    const [creditsToBuy, setCreditsToBuy] = useState<CreditToBuyWithDefinition[]>([]);

    useEffect(() => {
        _getCreditsOffer();
        _getInvoiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organizationId]);

    const _getCreditsOffer = () => {
        if (!organizationId) {
            return;
        }
        getCreditsOffer(organizationId).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            setOffer(res.data!);
        });
    }
    const _getInvoiceData = () => {
        if (!organizationId) {
            return;
        }
        getInvoiceData(organizationId).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!);
                return;
            }
            setInvoiceData(res.data!);
        });
    }


    const _select = (creditToBuy: CreditDefinition) => {
        setCreditsToBuy([{
            name: creditToBuy.name,
            quantity: 1,
            definition: creditToBuy
        }]);
        setOpenInvoiceData(true);
    }

    const _summaryDataClosed = () => {
        setOpenOrderSummary(false);
    }
    const _invoiceDataClosed = (data: { cancel?: boolean, skip?: boolean, invoiceData?: CreditInvoiceData }) => {
        setOpenInvoiceData(false);
        if (data.cancel) {
            return;
        }

        if (data.skip) {
            setOrderInvoiceData(null);
            setOpenOrderSummary(true);
            return;
        }

        if (data.invoiceData) {
            setOrderInvoiceData(data.invoiceData);
            setOpenOrderSummary(true);
            return;
        }
    }


    return <Wrapper>

        <div className="frame-box-wrapper individual-offer-wrapper">
            <div className="frame-box individual-offer">
                {t('organizationBillings.pricingScreen.individualOffer')}

                <a href="mailto:biuro@votenti.pl" className="cta">
                    {t('organizationBillings.pricingScreen.individualOfferCta')}
                </a>
            </div>
        </div>

        {
            offer ? null : <Loader/>
        }

        <div className="plans">

            {
                offer?.map((credit, index) => {
                    return <div className="plan" key={index}>

                        <div className="name">
                            {credit.name}
                        </div>

                        <div className="invitations">
                            {t('organizationBillings.pricingScreen.planInvitations')}
                            &nbsp;
                            <strong>{credit.invitationAmount === null
                                ? t('organizationBillings.pricingScreen.planInvitationsUnlimited')
                                : t('organizationBillings.pricingScreen.planInvitationsLimit', {limit: credit.invitationAmount})
                            }</strong>
                        </div>

                        <div className="description">
                            {credit.description}
                        </div>

                        <div className="price-gross">
                            {numberFormatter(credit.priceGross)} zł
                        </div>
                        <div className="price-net">
                            {numberFormatter(credit.priceNet)} zł { t('net') }
                        </div>

                        <button className="button" onClick={() => _select(credit)}>
                            {t('organizationBillings.pricingScreen.select')}
                        </button>

                    </div>
                })
            }

        </div>
        <div className="frame-box-wrapper">
            <div className="frame-box">
                {t('organizationBillings.pricingScreen.whatYouGet')}
            </div>
        </div>

        <div className="pros-list">
            <ul>

                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet1')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet2')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet3')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet4')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet5')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet6')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet7')}}></li>
                <li dangerouslySetInnerHTML={{__html: t('organizationBillings.pricingScreen.whatYouGetList.bullet8')}}></li>
            </ul>
        </div>


        <div className="frame-box-wrapper">
            <div className="frame-box">
                {t('organizationBillings.pricingScreen.process')}
            </div>
        </div>

        <div className="steps">
            <div className="step">
                <div className="number">1</div>
                <div
                    className="name">{t('organizationBillings.pricingScreen.processSteps.step1.name')}</div>
                <div className="description">
                    {t('organizationBillings.pricingScreen.processSteps.step1.description')}
                </div>
            </div>
            <div className="step">
                <div className="number">2</div>
                <div
                    className="name">{t('organizationBillings.pricingScreen.processSteps.step2.name')}</div>
                <div className="description">
                    {t('organizationBillings.pricingScreen.processSteps.step2.description')}
                </div>
            </div>
            <div className="step">
                <div className="number">3</div>
                <div
                    className="name">{t('organizationBillings.pricingScreen.processSteps.step3.name')}</div>
                <div className="description">
                    {t('organizationBillings.pricingScreen.processSteps.step3.description')}
                </div>
            </div>
        </div>


        <ErrorModal failure={error} onClose={() => setError(null)}/>


        <Modal width={500} show={openInvoiceData} onClose={() => setOpenInvoiceData(false)} backDropClose={false}>
            <OrganizationBillingsInvoiceData data={invoiceData!}
                                             onClose={_invoiceDataClosed}></OrganizationBillingsInvoiceData>
        </Modal>

        <Modal width={500} show={openOrderSummary} onClose={() => setOpenOrderSummary(false)} backDropClose={false}>
            <OrganizationBillingsOrderSummary invoiceData={orderInvoiceData}
                                              creditsToBuy={creditsToBuy}
                                              onClose={_summaryDataClosed}
            ></OrganizationBillingsOrderSummary>
        </Modal>

    </Wrapper>
}

export default OrganizationBillingsPricingScreen;
