import React, {useEffect, useState} from 'react';
import {Wrapper} from './EventVotingModal.styles';
import Modal, {ModalCloseFunction} from '../../../../components/Modal';
import {useBaseScreenHook} from '../../../../logic/core/base-screen.hook';
import {useParams} from 'react-router-dom';
import {
    AgendaPoint,
    EventType,
    SpecialMarker,
    VoteCast,
    Voting,
    VotingChoice,
    VotingStatus,
    VotingType
} from '../../../../logic/events/events.model';
import {activateVoting, closeVoting, finishVoting, makeVote, useEvent} from '../../../../logic/events/events';
import Loader from '../../../../components/Loader';
import PdfViewer from '../../../../components/PdfViewer';
import {downloadFile} from '../../../../logic/files/files.service';
import iconPdf from '../../../../assets/icon-pdf.svg';
import iconCalendar from '../../../../assets/icon-calendar.svg';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import VotingCancelModal from "../VotingCancelModal";
import OutcomeDecision from "../OutcomeDecision";
import {Table} from "../../../../components/Table/table";
import {AppRoutes} from "../../../../logic/core/routes";


const EventVotingModal = ({
                              onClose,
                              onCloseWithDuplicate,
                              agendaPointId,
                              voteId,
                              previewResults = false,
                          }: { onClose: ModalCloseFunction, onCloseWithDuplicate: (agenda: AgendaPoint, voting: Voting) => void, agendaPointId?: string, voteId?: string, previewResults: boolean, },) => {
    const {organizationId, eventId} = useParams();
    const {t, renderError, setError, setLoading, i18n, loading, navigate} = useBaseScreenHook();
    const [voting, setVoting] = useState<Voting | null>(null);
    const [agenda, setAgenda] = useState<AgendaPoint | null>(null);
    const [myVote, setMyVote] = useState<VoteCast | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const {event} = useEvent();
    const [finishVotingConfirmation, setFinishVotingConfirmation] = useState<boolean>(false);
    const [votingToCancel, setVotingToCancel] = useState<boolean>(false);

    const dateFormatter = new Intl.DateTimeFormat(i18n.language, {
        dateStyle: 'short',
        timeStyle: 'short'
    }).format;
    const percentFormatter = new Intl.NumberFormat(i18n.language, {
        style: 'percent',
        maximumFractionDigits: 2,
    }).format;
    const percentFormat2 = new Intl.NumberFormat(i18n.language, {minimumFractionDigits: 2,  maximumFractionDigits: 2}).format;
    useEffect(() => {
        const agenda: AgendaPoint | null = event?.content?.agenda.items.find((a) => a.id === agendaPointId) ?? null;
        setAgenda(agenda);
        const voting: Voting | null = agenda?.votes.find((v) => v.id === voteId) ?? null;
        setVoting(voting);
        const cast: VoteCast | null = voting?.voteCasts.find((c) => c.participantId === event?.participant?.id && c.choice != null) ?? null;
        setMyVote(cast);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agendaPointId, event, voteId]);

    useEffect(() => {

        if (voting?.documentId) {
            downloadFile(voting.documentId).then((res) => {
                if (res.isFailure()) {
                    setError(res.failure!)
                    return;
                }

                setFile(res.data?.data!);
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [voting?.documentId])

    const _finishVoting = async () => {
        setLoading(true);
        const res = await finishVoting(organizationId!, eventId!, agendaPointId!, voteId!);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        setFinishVotingConfirmation(false);

    }

    const _makeVote = async (vote: VotingChoice) => {
        if (myVote || voting?.status !== VotingStatus.InProgress || !voting.availableActions.canBeCast) {
            return;
        }
        const res = await makeVote(organizationId!, eventId!, agendaPointId!, voteId!, vote);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }

    const _votingCancelModalClosed = (openNew: boolean) => {
        setVotingToCancel(false);
        if (openNew) {
            onCloseWithDuplicate(agenda!, voting!);
        }
    }

    const _close = async () => {
        setLoading(true);
        const res = await closeVoting(organizationId!, eventId!, agendaPointId!, voteId!);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }

        onClose();

    }

    const _activeVoting = async () => {
        setLoading(true);
        const res = await activateVoting(organizationId!, eventId!, agendaPointId!, voteId!);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
    }
    const _goToParticipants = () => {
        navigate(`/${AppRoutes.organization}/${organizationId}/${AppRoutes.event}/${eventId}/${AppRoutes.eventParticipants}`)
    }
    if (!voting) {
        return <Wrapper>
            <Loader/>
        </Wrapper>
    }

    return <Wrapper>
        <div className="voting">
            <div className="left">
                {
                    voting.documentId
                        ? <>
                            <div className="document-label">
                                <div className="icon">
                                    <img src={iconPdf} alt="icon"/>
                                </div>

                                <div className="label">
                                    <div className="name">
                                        {voting.name}
                                    </div>
                                    <div className="description">
                                        {t('eventVotingModal.documentDescription')}
                                    </div>
                                </div>
                            </div>
                            <div className="document-wrapper">
                                {file ? <PdfViewer viewOnly={true} currentFile={file} fileChanged={() => {
                                }}/> : <Loader/>}

                            </div>
                        </>
                        : null
                }
            </div>
            <div className="right">
                <div className="content">
                    <div className="status">
                        <div className="voting-type">
                            {t(`eventVotingModal.type.${voting.type}`)}
                        </div>

                        <div className="voting-status">
                            <div className={`dot ${voting.status}`}></div>

                            {t(`eventVotingModal.status.${voting.status}`)}
                        </div>
                    </div>

                    <div className="intent">
                        <div className="title">
                            {voting.name}
                        </div>

                        <div className="description">
                            {voting.description}
                        </div>

                        <div className="time">
                            <div className="start">
                                <img src={iconCalendar} alt="icon"/>
                                {t('eventVotingModal.start')}: {voting.startTimestamp ? dateFormatter(new Date(voting.startTimestamp)) : '-'}
                            </div>
                            <div className="end">
                                {t('eventVotingModal.end')}: {voting.finishTimestamp ? dateFormatter(new Date(voting.finishTimestamp)) : '-'}
                            </div>
                        </div>

                        <div className="actions">
                            {
                                event?.permissions.canManageVote && voting.availableActions.canBeFinished
                                    ? <button className={'button'} onClick={() => setFinishVotingConfirmation(true)}>
                                        {t('eventVotingModal.finishVoting')}
                                    </button>
                                    : null
                            }
                            {
                                event?.permissions.canManageVote && voting.availableActions.canBeActivated
                                    ? <button className={'button'} onClick={() => _activeVoting()} disabled={loading}>
                                        {t('eventAgenda.activateVoting')}
                                    </button>
                                    : null
                            }
                            {
                                event?.permissions.canManageVote && event.content?.type === EventType.LIVE
                                    ? voting.open
                                        ? voting.availableActions.canBeClosed
                                            ? <button className={'button'} onClick={() => _close()}>
                                                {t('eventVotingModal.closeWindow')}
                                            </button>
                                            : null
                                        : voting.status !== VotingStatus.InProgress
                                            ? <button className={'button'} onClick={() => onClose()}>
                                                {t('eventVotingModal.closeWindow')}
                                            </button>
                                            : null
                                    : previewResults || event?.content?.type === EventType.CIRCULAR || (voting.status === VotingStatus.Pending && event?.permissions.canEnterVoteInPendingStatus)
                                        ? <button className={'button'} onClick={() => onClose()}>
                                            {t('eventVotingModal.closeWindow')}
                                        </button> : null
                            }
                            {
                                event?.permissions.canManageVote && voting.availableActions.canBeCanceled
                                    ? <button className={'button warn'} onClick={() => setVotingToCancel(true)}>
                                        {t('eventAgenda.cancelVoting')}
                                    </button>
                                    : null
                            }

                        </div>

                        <div className="separator"></div>


                    </div>

                    {event?.permissions.canCastVote
                        ? <>
                            <div className="intent">
                                <div className="title">
                                    {t('eventVotingModal.votingTitle')}
                                </div>
                            </div>
                            <div className="my-vote">
                                <div className={`vote ${myVote?.choice}`}>
                                    <div className="timer">
                                        {/*0:23*/}
                                    </div>
                                    <div className={`selection ${myVote ? 'selected' : ''}`}>
                                        {myVote?.choice
                                            ? `${t(`eventVotingModal.choiceSelected`)} ${t(`eventVotingModal.choice.${myVote.choice}`)}`
                                            : voting.status === VotingStatus.InProgress ? t('eventVotingModal.voteNotMade') : t(`eventVotingModal.choice.NoVote`)
                                        }

                                    </div>
                                </div>
                                <div className="options">
                                    <div
                                        className={`option For ${myVote?.choice || voting.status !== VotingStatus.InProgress || !voting.availableActions.canBeCast ? 'disabled' : ''} ${myVote?.choice === VotingChoice.For ? 'selected' : ''}`}
                                        onClick={() => _makeVote(VotingChoice.For)}>
                                        {t(`eventVotingModal.choice.For`)}
                                    </div>
                                    <div
                                        className={`option Against ${myVote?.choice || voting.status !== VotingStatus.InProgress || !voting.availableActions.canBeCast ? 'disabled' : ''} ${myVote?.choice === VotingChoice.Against ? 'selected' : ''}`}
                                        onClick={() => _makeVote(VotingChoice.Against)}>
                                        {t(`eventVotingModal.choice.Against`)}
                                    </div>
                                    <div
                                        className={`option Abstain ${myVote?.choice || voting.status !== VotingStatus.InProgress || !voting.availableActions.canBeCast ? 'disabled' : ''} ${myVote?.choice === VotingChoice.Abstain ? 'selected' : ''}`}
                                        onClick={() => _makeVote(VotingChoice.Abstain)}>
                                        {t(`eventVotingModal.choice.Abstain`)}
                                    </div>
                                </div>
                            </div>
                        </>
                        : null
                    }


                    <div className="intent">
                        <div className="title">
                            {t('eventVotingModal.votingResults')}
                        </div>
                    </div>

                    <div className="results-wrapper">
                        <div className="results">
                            <div className="results-title">
                                {t('eventVotingModal.votesMade')}
                            </div>

                            <div className="users">

                                {
                                    voting.voteCasts.filter(c => c.signed).map((c) => {
                                        return <div key={c.participantId}
                                                    className={`user ${c.castedVote ? 'casted-vote' : ''} ${(voting?.status === VotingStatus.Passed || voting.status === VotingStatus.NotPassed || voting.status === VotingStatus.Finished) && c.choice}`}>
                                            {c.participantName}
                                        </div>;
                                    })
                                }
                            </div>
                        </div>

                        <div className="results-votes">
                            {
                                voting?.status === VotingStatus.Passed || voting.status === VotingStatus.NotPassed || voting.status === VotingStatus.Finished
                                    ?
                                    <>
                                        <div className="vote">
                                            <div className="counter For">
                                                {voting.results?.forResults.total}
                                            </div>
                                            <div className="label">
                                                {t('eventVotingModal.choice.For')}
                                            </div>
                                        </div>
                                        <div className="vote">
                                            <div className={`counter Against`}>
                                                {voting.results?.againstResults.total}
                                            </div>
                                            <div className="label">
                                                {t('eventVotingModal.choice.Against')}
                                            </div>
                                        </div>
                                        <div className="vote">
                                            <div className="counter Abstain">
                                                {voting.results?.abstainResults.total}
                                            </div>
                                            <div className="label">
                                                {t('eventVotingModal.choice.Abstain')}
                                            </div>
                                        </div>
                                        <div className="separator"></div>

                                        <div className="vote">
                                            <div className="counter NoVote">
                                                {voting.results?.totals.noVote}
                                            </div>
                                            <div className="label">
                                                {t('eventVotingModal.choice.NoVote')}
                                            </div>
                                        </div>
                                        <div className="vote link" onClick={_goToParticipants}>
                                            <div className="counter Absent">
                                                {voting.results?.totals.absent}
                                            </div>
                                            <div className="label">
                                                {t('eventVotingModal.choice.Absent')}
                                            </div>
                                        </div>

                                    </>
                                    : <div className={'results-info'}>{t('eventVotingModal.resultsInfo')}</div>
                            }

                        </div>

                    </div>

                    {
                        voting.availableActions.canDecideOnOutcome && event?.permissions.canDecideOnVoteOutcome ? <>
                                <div className="intent">
                                    <div className="title">
                                        {t('eventVotingModal.outcomeDecisionTitle')}
                                    </div>
                                </div>

                                <div className="outcome-decision">
                                    <OutcomeDecision itemId={agendaPointId!} voteId={voteId!}/>
                                </div>
                            </>
                            : null
                    }

                    {
                        voting.status === VotingStatus.Passed || voting.status === VotingStatus.NotPassed || voting.status === VotingStatus.Finished
                            ? <>
                                <div className="intent">
                                    <div className="title">
                                        {t('eventVotingModal.summaryTitle')}
                                    </div>
                                </div>

                                <div className="summary">

                                    <div className="row">
                                        <div className="label">{t('eventVotingModal.summary.totalCasted')}</div>
                                        <div className="value">{voting.results?.totals.casted}</div>
                                    </div>
                                    <div className="row">
                                        <div className="label">{t('eventVotingModal.summary.totalSigned')}</div>
                                        <div className="value">{voting.results?.totals.signed}</div>
                                    </div>
                                    <div className="table">
                                        <Table>
                                            <thead>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td>{t('eventVotingModal.summary.totalCastedPercent')}</td>
                                                <td>{t('eventVotingModal.summary.totalSignedPercent')}</td>
                                                <td>{t('eventVotingModal.summary.totalBodyPercent')}</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>{t('eventVotingModal.summary.forResults')}</td>
                                                <td>{voting.results?.forResults.total}</td>
                                                <td>{voting.results?.forResults.fractionOfAllCastedVotes === null ? '-' : percentFormatter(voting.results?.forResults.fractionOfAllCastedVotes!)}</td>
                                                <td>{voting.results?.forResults.fractionOfAllSignedVotes === null ? '-' : percentFormatter(voting.results?.forResults.fractionOfAllSignedVotes!)}</td>
                                                <td>{voting.results?.forResults.fractionOfAllBodyVotes === null ? '-' : percentFormatter(voting.results?.forResults.fractionOfAllBodyVotes!)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('eventVotingModal.summary.againstResults')}</td>
                                                <td>{voting.results?.againstResults.total}</td>
                                                <td>{voting.results?.againstResults.fractionOfAllCastedVotes === null ? '-' : percentFormatter(voting.results?.againstResults.fractionOfAllCastedVotes!)}</td>
                                                <td>{voting.results?.againstResults.fractionOfAllSignedVotes === null ? '-' : percentFormatter(voting.results?.againstResults.fractionOfAllSignedVotes!)}</td>
                                                <td>{voting.results?.againstResults.fractionOfAllBodyVotes === null ? '-' : percentFormatter(voting.results?.againstResults.fractionOfAllBodyVotes!)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('eventVotingModal.summary.abstainResults')}</td>
                                                <td>{voting.results?.abstainResults.total}</td>
                                                <td>{voting.results?.abstainResults.fractionOfAllCastedVotes === null ? '-' : percentFormatter(voting.results?.abstainResults.fractionOfAllCastedVotes!)}</td>
                                                <td>{voting.results?.abstainResults.fractionOfAllSignedVotes === null ? '-' : percentFormatter(voting.results?.abstainResults.fractionOfAllSignedVotes!)}</td>
                                                <td>{voting.results?.abstainResults.fractionOfAllBodyVotes === null ? '-' : percentFormatter(voting.results?.abstainResults.fractionOfAllBodyVotes!)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t('eventVotingModal.summary.signedButNotVotingResults')}
                                                </td>
                                                <td>{voting.results?.signedButNotVotingResults.total}</td>
                                                <td>{voting.results?.signedButNotVotingResults.fractionOfAllCastedVotes === null ? '-' : percentFormatter(voting.results?.signedButNotVotingResults.fractionOfAllCastedVotes!)}</td>
                                                <td>{voting.results?.signedButNotVotingResults.fractionOfAllSignedVotes === null ? '-' : percentFormatter(voting.results?.signedButNotVotingResults.fractionOfAllSignedVotes!)}</td>
                                                <td>{voting.results?.signedButNotVotingResults.fractionOfAllBodyVotes === null ? '-' : percentFormatter(voting.results?.signedButNotVotingResults.fractionOfAllBodyVotes!)}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>

                                <div className="intent">
                                    <div className="title">
                                        {t('eventVotingModal.settingsTitle')}
                                    </div>
                                </div>

                                <div className="settings">
                                    <div className="row">
                                        <div className="label">
                                            {t('eventVotingModal.settings.secretLabel')}
                                        </div>
                                        <div className="value">
                                            {voting.type === VotingType.Secret ? t('yes') : t('no')}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="label">
                                            {t('eventVotingModal.settings.modeLabel')}
                                        </div>
                                        <div className="value">
                                            <div>
                                                {t(`eventVotingCreationModal.modeType.${voting.mode.type}`)}
                                            </div>
                                            <div>
                                                {
                                                    voting.mode.modeType ?
                                                        t(`eventVotingCreationModal.modeType.${voting.mode.modeType?.type}`)
                                                        : null
                                                }
                                            </div>
                                            <div>
                                                {
                                                    voting.mode.modeType?.number ?? null
                                                }
                                                {
                                                    voting.mode.modeType?.percent ? percentFormat2(voting.mode.modeType?.percent) + '%' : null
                                                }
                                                {
                                                    voting.mode.modeType?.numerator ? voting.mode.modeType?.numerator + '/' + voting.mode.modeType?.denominator : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="label">
                                            {t('eventVotingModal.settings.quorumRequiredLabel')}
                                        </div>
                                        <div className="value">
                                            {voting.quorum ? t('yes') : t('no')}
                                        </div>
                                    </div>
                                    {
                                        voting.quorum ?
                                            <div className="row">
                                                <div className="label">
                                                    {t('eventVotingModal.settings.quorumLabel')}
                                                </div>
                                                <div className="value">
                                                    <div>
                                                        {t(`eventVotingCreationModal.quorumType.${voting.quorum?.type}`)}
                                                    </div>
                                                    <div>
                                                        {voting.quorum.quorumType
                                                            ? t(`eventVotingCreationModal.quorumType.${voting.quorum?.quorumType?.type}`)
                                                            : null}
                                                    </div>
                                                    <div>
                                                        {
                                                            voting.quorum.quorumType?.number ?? null
                                                        }
                                                        {
                                                            voting.quorum.quorumType?.percent ? percentFormat2(voting.quorum.quorumType?.percent) + '%' : null
                                                        }
                                                        {
                                                            voting.quorum.quorumType?.numerator ? voting.quorum.quorumType?.numerator + '/' + voting.quorum.quorumType?.denominator : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        voting.voteCasts.some((c) => c.specialMarkers.includes(SpecialMarker.RequiredVote)) ?
                                            <div className="row">
                                                <div className="label">
                                                    {t('eventAgenda.requiredVoteMember')}
                                                </div>
                                                <div className="value">
                                                    {voting.voteCasts
                                                        .filter((c) => c.specialMarkers.includes(SpecialMarker.RequiredVote))
                                                        .map(c => c.participantName)
                                                        .join(', ')}
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {
                                        voting.voteCasts.some((c) => c.specialMarkers.includes(SpecialMarker.DecidingVote)) ?
                                            <div className="row">
                                                <div className="label">
                                                    {t('eventAgenda.decidingVoteMember')}
                                                </div>
                                                <div className="value">
                                                    {voting.voteCasts
                                                        .filter((c) => c.specialMarkers.includes(SpecialMarker.DecidingVote))
                                                        .map(c => c.participantName)
                                                        .join(', ')}
                                                </div>
                                            </div>
                                            : null
                                    }
                                    {voting.excludedParticipants.length ?
                                        <div className="row">
                                            <div className="label">
                                                {t('eventAgenda.excludedMembers')}
                                            </div>
                                            <div className="value">
                                                {voting.excludedParticipants.join(', ')}
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                            </>
                            : null
                    }


                </div>
            </div>

        </div>

        <Modal width={600} show={finishVotingConfirmation} onClose={() => setFinishVotingConfirmation(false)}>
            <ConfirmationModal
                reject={() => setFinishVotingConfirmation(false)}
                accept={() => _finishVoting()}
                acceptLabel={t('eventVotingModal.finishVotingConfirmationConfirm')}
                title={t('eventVotingModal.finishVotingConfirmation')}
            />
        </Modal>
        <Modal width={800} show={votingToCancel} onClose={() => setVotingToCancel(false)}>
            <VotingCancelModal agenda={agenda!} voting={voting}
                               onClose={_votingCancelModalClosed}/>
        </Modal>
        {renderError()}
    </Wrapper>
}


export default EventVotingModal;
