import React, {useContext, useEffect, useRef} from 'react';
import {Wrapper} from './FilesPicker.styles';
import {ModalType} from '../Toast';
import {ToastContext} from '../Toast/toastStateProvider';
import {useBaseScreenHook} from '../../logic/core/base-screen.hook';
export interface SupportedFile {
    mimeType: string;
    displayValue: string;
}
const FilesPicker = ({
                         fileChange,
                         supportedFiles,
                         maxSizeInMB
                     }: { maxSizeInMB: number | null, supportedFiles: SupportedFile[] | null, fileChange: (file: File | null) => void }) => {
    const inputFileRef = useRef(null);
    const wrapperRef = useRef(null);

    const {t} = useBaseScreenHook();
    const [, setToastState] = useContext(ToastContext);

    useEffect(() => {
        if (wrapperRef.current) {
            const el: HTMLElement = wrapperRef.current;
            el.ondragover = el.ondragenter = function (evt) {
                evt.preventDefault();
                (evt.target as HTMLLabelElement).classList.add('over');
            };
            el.ondragleave = el.ondragend = function (evt) {
                evt.preventDefault();
                (evt.target as HTMLLabelElement).classList.remove('over');
            };

            el.ondrop = (evt) => {
                (evt.target as HTMLLabelElement).classList.remove('over');
                evt.preventDefault();
                evt.stopPropagation();
                _onFileChange({
                    target: {
                        files: evt.dataTransfer!.files,
                    }
                });
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wrapperRef.current]);

    const _onFileChange = (e: any) => {
        fileChange(null);
        const _file: File = e.target.files[0];
        const _fileExtension: string = _file.name.split('.').reverse()[0];
        if (supportedFiles && ((_file.type && !supportedFiles.some(s => s.mimeType === _file.type)) || (!_file.type && !supportedFiles.some((s) => s.displayValue.toLowerCase() === _fileExtension.toLowerCase())))) {
            e.target.value = null;
            setToastState({
                type: ModalType.warn,
                message: t('errors.fileError')
            });
            return;
        }
        if (maxSizeInMB && _file.size > maxSizeInMB * 1024 * 1024) {
            e.target.value = null;
            setToastState({
                type: ModalType.warn,
                message: t('errors.fileSizeError'),
            });
            return;
        }
        setTimeout(() => {
            fileChange(_file);
        })
        e.target.value = null
    }
    return <Wrapper className={'file-picker-element'}>
        <div className="file-picker-wrapper">
            <label className="file-picker" ref={wrapperRef}>
                <input type="file" accept={supportedFiles?.map(s => s.mimeType)?.join(',')} id="input-file" ref={inputFileRef}
                       onChange={_onFileChange}/>
                <div className="drag-and-drop-label">
                    <span className="default">{ t('filePicker.dragAndDrop') }</span>
                    <span className="drop">{ t('filePicker.dropFile') }</span>
                </div>
                <div className="add-file">
                    { t('filePicker.addFile') }
                </div>
            </label>

            <div className="description">
                {
                    supportedFiles
                        ? <>
                            <div className="label">
                                { t('filePicker.fileTypesAllowed') }
                            </div>
                            <div className="value">
                                {supportedFiles?.map((type) => type.displayValue).join(', ')}
                            </div>
                        </>
                        : null
                }

                {maxSizeInMB !== null ? <>
                        <div className="label">
                            { t('filePicker.fileSize') }
                        </div>
                        <div className="value">
                            { t('filePicker.MBSize', { maxSizeInMB, maxSizeInKB: maxSizeInMB * 1024 }) }
                        </div>
                    </>
                    : null
                }
            </div>
        </div>
    </Wrapper>
}

export default FilesPicker;
