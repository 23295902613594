import React, {useEffect} from 'react';
import {Wrapper} from './Event.styles';
import TopBar from '../../components/TopBar';
import MainContainer from '../../components/MainContainer';
import {matchRoutes, NavLink, useLocation, useSearchParams} from 'react-router-dom';
import {AppRoutes} from '../../logic/core/routes';
import {useBaseScreenHook} from '../../logic/core/base-screen.hook';
import {useEvent} from "../../logic/events/events";
import EventStartCountdown from "../../components/EventStartCountdown";
import {EventType} from "../../logic/events/events.model";
import Select from "../../components/Select";

const Event = () => {
    const {t, navigate, renderError} = useBaseScreenHook();
    const {event} = useEvent();
    const location = useLocation();
    const [params] = useSearchParams();
    const participantId = params.get('representedParticipantId');

    useEffect(() => {
        if (event?.attendanceOptions.length && !event?.attendanceOptions.find(a => a.participantId === participantId)) {
            navigate(`${location.pathname}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event?.attendanceOptions])

    useEffect(() => {
        return () => {
            localStorage.removeItem('event-source-location');
        }
    }, [])

    useEffect(() => {
        const routes = [{path: `/${AppRoutes.organization}/:organizationId/${AppRoutes.event}/:eventId/${AppRoutes.eventInfo}`}]

        if (event?.content?.type === EventType.CIRCULAR) {
            const route = matchRoutes(routes, location)
            if (route) {
                navigate(`${AppRoutes.eventAgenda}${location.search}`);
            }
        }

    }, [location, event, navigate])


    const _changeContext = (index: number) => {
        const attendanceOption = event?.attendanceOptions[index];
        if (attendanceOption?.invitationUrl) {
            window.location.href = attendanceOption.invitationUrl;
            return;
        }
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('representedParticipantId', attendanceOption?.participantId ?? '');
        navigate(`${location.pathname}?${searchParams.toString()}`);
    }

    return <Wrapper>
        <TopBar backArrow={true}>
            {
                event?.attendanceOptions.length
                    ? <div className="context-selector">
                        <Select label={''}
                                currentValue={event?.attendanceOptions.findIndex(p => p.participantId === participantId)}
                                onChange={(e) => _changeContext(e)}
                        >
                            {event?.attendanceOptions?.map((p, i) => {
                                return <option value={i}
                                               key={'opt-' + p.participantId + '-' + p.attendeeName + '-' + i}>
                                    {t(p.participantId ? 'event.menu.represent.body' : 'event.menu.represent.administrator')} {p.attendeeName}
                                </option>
                            })}
                        </Select>
                    </div>
                    : null
            }

        </TopBar>
        {
            !!event?.error
                ? <div className={'error'}>{event.error}</div>
                : <>

                    <EventStartCountdown
                        status={event?.content?.meetingStatus ?? null}/>

                    <MainContainer>

                        {
                            event?.content?.type === EventType.LIVE
                                ? <NavLink to={`${AppRoutes.eventInfo}${location.search}`} className="nav-link">
                                    {t('event.menu.info')}
                                </NavLink> : null
                        }


                        <NavLink to={`${AppRoutes.eventAgenda}${location.search}`} className="nav-link">
                            {event?.content?.type === EventType.LIVE
                                ? t('event.menu.agenda')
                                : t('event.menu.voting')
                            }
                        </NavLink>

                        <NavLink to={`${AppRoutes.documents}${location.search}`} className="nav-link">
                            {t('event.menu.documents')}
                        </NavLink>

                        {event?.permissions.canManageParticipants || event?.permissions.canManageGuests ?
                            <NavLink to={`${AppRoutes.eventParticipants}${location.search}`} className="nav-link">
                                {t('event.menu.participants')}
                            </NavLink> : null}




                    </MainContainer>
                </>
        }
        {renderError()}
    </Wrapper>
}

export default Event;
