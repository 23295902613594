import React from "react";
import {Wrapper} from "./VotingCancelModal.styles";
import {AgendaPoint, Voting} from "../../../../logic/events/events.model";
import {cancelVoting} from "../../../../logic/events/events";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import {useParams} from "react-router-dom";
import FormField from "../../../../components/FormField";
import {useForm} from "../../../../logic/core/form.hook";
import {Textarea} from "../../../../components/Textarea/Textarea.styles";

const VotingCancelModal = ({agenda, voting, onClose}:{agenda: AgendaPoint | undefined, voting: Voting | undefined, onClose: (openNew: boolean) => void}) => {
    const {renderError, t, setError, loading, setLoading} = useBaseScreenHook();
    const {organizationId, eventId} = useParams();
    const {formControl, formValid, renderValidationMessage, formValue} = useForm<{reason: string | null}>({
        reason: {
            value: '',
        }
    })
    const _cancelVoting = async (openNew: boolean) => {
        if (!formValid()) {
            return;
        }
        setLoading(true);
        const res = await cancelVoting(organizationId!, eventId!, agenda!.id, voting!.id, formValue.reason);
        setLoading(false);
        if (res.isFailure()) {
            setError(res.failure!);
            return;
        }
        onClose(openNew);
    }

    return <Wrapper>

        <div className="title">
            { t('votingCancelModal.cancelVoting') }
        </div>

        <FormField>
            <div className="label">
                {t('eventAgenda.cancelReason')}
            </div>
            <Textarea>
                        <textarea
                            value={formControl('reason').value}
                            onChange={(e) => formControl('reason').patchValue(e.target.value)}/>
            </Textarea>
            {renderValidationMessage('reason')}
        </FormField>


        <div className="buttons">

            <button className="button text muted" onClick={() => onClose(false)}>
                { t('votingCancelModal.close') }
            </button>

            <button className="button outline" disabled={loading} onClick={() => _cancelVoting(false)}>
                { t('votingCancelModal.cancel') }
            </button>

            <button className="button" disabled={loading} onClick={() => _cancelVoting(true)}>
                { t('votingCancelModal.repeat') }
            </button>

        </div>

        {renderError()}
    </Wrapper>
}

export default VotingCancelModal;
