import React, {useEffect, useState} from "react";
import {Wrapper} from "./UserEvents.styles";
import {getUserEvents} from "../../../logic/events/events";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import {EventParticipantInvitation} from "../../../logic/events/events.model";
import InvitationsTable from "../../../components/InvitationsTable";

const UserEvents = () => {

    const {renderError, setError, t} = useBaseScreenHook();
    const [events, setEvents] = useState<EventParticipantInvitation[] | null>(null);


    useEffect(() => {
        _getEvents();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const _getEvents = async () => {
        const res = await getUserEvents();
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }

        setEvents(res.data!);
    }



    return <Wrapper>
        <div className="title">
            {t('events.title')}
        </div>
        <div className="description">
            {t('events.description')}
        </div>

       <InvitationsTable data={events}/>

        {renderError()}
    </Wrapper>
}

export default UserEvents;