import React, {useEffect, useRef, useState} from "react";
import {Wrapper} from "./EventParticipantInvitationDetails.styles";
import {getParticipantInvitation} from "../../../logic/events/events";
import {
    EventModel,
    EventParticipantInvitation, EventTimerData,
    EventType
} from "../../../logic/events/events.model";
import {Organization, ProceedingType} from "../../../logic/organizations/organizations.model";
import {useParams} from "react-router-dom";
import {useBaseScreenHook} from "../../../logic/core/base-screen.hook";
import Loader from "../../../components/Loader";
import EventHeader from "../../Event/EventHeader";
import {useAppStateHook} from "../../../logic/core/appState.hook";
import NotLoggedIn from "./NotLoggedIn";
import SigningInvitation from "./SigningInvitation";
import {AppRoutes} from "../../../logic/core/routes";
import EventStartCountdown from "../../../components/EventStartCountdown";
import {useEventTimer} from "../../../logic/events/event-timer";

const EventParticipantInvitationDetails = () => {
    const {appState} = useAppStateHook();
    const {setError, renderError, t, navigate} = useBaseScreenHook();
    const {participantId} = useParams();

    const startDate = useRef<Date | null>(null);
    useEffect(() => {
        _getInvitation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [invitation, setInvitation] = useState<EventParticipantInvitation | null>(null);
    const [event, setEvent] = useState<EventModel | null>(null);
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [allowNotLoggedUserToJoin, setAllowNotLoggedUserToJoin] = useState<boolean>(false);
    const eventTimerData: EventTimerData = useEventTimer({eventIdd: invitation?.event?.id});

    const _getInvitation = async () => {
        const res = await getParticipantInvitation(participantId!);
        if (res.isFailure()) {
            setError(res.failure!);
            return
        }
        const invitation = res.data!;
        startDate.current = new Date(invitation.event.startDate);

        const event: EventModel = {
            content: {
                basic: {
                    name: invitation.event.name,
                    startDate: invitation.event.startDate,
                    body: {
                        name: invitation.event.bodyName,
                        id: ''
                    },
                    id: '',
                    type: EventType.LIVE,
                    proceedings: ProceedingType.ORDINARY,
                },
                meetingStatus: invitation.event.status,
            }

        } as EventModel;

        setEvent(event);

        const organization: Organization = {
            name: invitation.event.organizationName,
        } as Organization;


        setOrganization(organization);
        setInvitation(invitation);
        if (invitation.participant.alreadySigned) {
            navigate(`/${AppRoutes.organization}/${invitation?.event.organizationId}/${AppRoutes.event}/${invitation?.event.id}?representedParticipantId=${participantId!}`)
        }
    }


    if (!invitation) {
        return <Wrapper>
            <Loader/>
            {renderError()}

        </Wrapper>;
    }
    return <Wrapper>

        {invitation?.event?.id
            ? <EventStartCountdown status={event?.content?.meetingStatus ?? null} eventId={invitation.event.id}/>
            : null}


        <EventHeader event={event!} organization={organization!}/>

        <div className="invitation-for">
            <p className={'label'}>
                {t('eventParticipantInvitationScreen.invitationFor')}
            </p>
            <p className={'name'}>
                {invitation.participant.bodyMemberName}
            </p>

            <div className="votes">
                <div className="label">
                    { t('eventParticipantInvitationDetails.voteCount', { voteCount: invitation.participant.voteCount }) }
                </div>
            </div>
            {
                invitation.participant.share === null
                    ? null
                    : <div className="share">
                        <div className="label">
                            { t('eventParticipantInvitationDetails.shareValue', { share: invitation.participant.share }) }
                        </div>
                    </div>
            }

        </div>

        {
            !eventTimerData.canSignIn
                ? t('eventParticipantInvitationDetails.singInTitemout')
                : null
        }

        {
            eventTimerData.canSignIn ?
                !appState.user && !allowNotLoggedUserToJoin
                    ? <NotLoggedIn invitation={invitation} joinAction={() => setAllowNotLoggedUserToJoin(true)}/>
                    : <SigningInvitation/>
                : null
        }


        {renderError()}
    </Wrapper>
}

export default EventParticipantInvitationDetails;
