import React, {useEffect, useState} from "react";
import {Wrapper} from "./DocumentPreviewModal.styles";
import {ModalCloseFunction} from "../../../../components/Modal";
import {downloadFile} from "../../../../logic/files/files.service";
import {useBaseScreenHook} from "../../../../logic/core/base-screen.hook";
import PdfViewer from "../../../../components/PdfViewer";
import Loader from "../../../../components/Loader";

const DocumentPreviewModal = ({
                                  documentId, onClose
                              }: {
    documentId: string | null, onClose: ModalCloseFunction
}) => {
    const {setError, renderError, t} = useBaseScreenHook();
    const [file, setFile] = useState<File | null>(null);

    useEffect(() => {
        downloadFile(documentId!).then((res) => {
            if (res.isFailure()) {
                setError(res.failure!)
                return;
            }

            setFile(res.data?.data!);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Wrapper>
        <div className="title">
            { t('documentPreviewModal.content') }
        </div>
        {
            file
                ? <div className={'document-wrapper'}>
                    <PdfViewer fileChanged={() => {
                }} viewOnly={true} currentFile={file}/>
            </div>
                : <Loader/>
        }

        {renderError()}
    </Wrapper>
}

export default DocumentPreviewModal;
